<template>
  <div class="">
    <div :class="[isDragging?'drag-in-file-border':'upload-file-border','mt-2']"
         @dragover.prevent
         @dragenter="dragEnter"
         @dragleave="dragLeave"
         @drop="dropFile"
         @click="$refs.fileUpload.click()"
         >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="upload-logo">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 15V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 15M8 11L12 15M12 15L16 11M12 15V3" stroke="#0397CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-if="isDragging" class="mt-1 text-center w-100 py-2 upload-file-text">
            Drop Here...
        </div>
        <div v-else class="mt-1 w-100 text-center" >
          <div class="upload-file-text">
            Upload {{ name }}
          </div>
          <div class="upload-file-text-grey">
            or drag it here
          </div>
        </div>
      </div>
      
    </div>
    <input multiple @input="handleInputFile" type="file" name="" :accept="accept" ref="fileUpload" class="d-none" id="">

  </div>
  </template>
  
<script>
export default {
    props:{
        name:String,
        accept:String,
        maxFileSize:Number
    },
    data() {
    return {
      isDragging: false
    };
  },
  methods: {
    dragEnter() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    validateFileSize(file) {
    if (this.maxFileSize !== null) {
      const fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB > this.maxFileSize) {
        this.$toasted.show(`File size exceeds the ${this.maxFileSize} MB limit.`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      
        return false;
      }
    }
    return true;
  },
    dropFile(event) {
      event.preventDefault();
      this.isDragging = false;
      const file = event.dataTransfer.files;
      if (this.validateFileSize(file)) {
      this.handleFile(file);
    }
     
    },
    handleInputFile(event){ 
      const file =event.target.files; 
    if (this.validateFileSize(file)) {
      this.handleFile(file);
    }
    },
    handleFile(file) {
      this.$emit('files',file)
    }
  }
}
</script>

<style>
.upload-file-border{
    display: flex;
padding: 22px 0px;
justify-content: center;
align-items: center;
flex: 1 0 0;
align-self: stretch;
border-radius: 4px;
border: 1px dashed #B3AFB6;
cursor:pointer;
}
.drag-in-file-border{
    display: flex;
padding: 22px 0px;
justify-content: center;
align-items: center;
flex: 1 0 0;
align-self: stretch;
border-radius: 4px;
border: 1px solid #B3AFB6;
}
.upload-file-text{
    color: #1F1F28;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 16.8px */
}
.upload-file-text-grey{
    color: #73738D;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 140%;
}
</style>